import React, {useState} from "react";
import "./style.css";
// https://www.npmjs.com/package/react-collapsible
import Collapsible from 'react-collapsible';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export default function PanelCollapse(props) {
    return (
        <>
            <Collapsible trigger={Header(props)} open={props?.open !== undefined ? props.open : true}>
                {props.children}
            </Collapsible>

            {/* <hr className="pc-line"/> */}
        </>
    );
};

const Header = (props) => {
    const [aberto, setAberto] = useState(props?.open !== undefined ? props.open : true);

    return (
        <>
            <div className="pc-container" onClick={() => setAberto(!aberto)}>
                {aberto 
                ? <MdKeyboardArrowUp size={24}/>            
                : <MdKeyboardArrowDown size={24}/>
                }
                <span>{props.titulo ? props.titulo : "Pesquisa"}</span>
            </div>
        </>
    )
}