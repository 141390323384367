import React, {useState, useEffect, useContext, useRef} from "react";
import "./style.css";
import Package from "../../../package.json";

import { AuthContext } from "../../context/Auth";
import { Oval } from  "react-loader-spinner";
import { FaUserLarge, FaLock } from "react-icons/fa6";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { ButtonOK } from "../../components/Button";
import { getParamURL } from "../../library";
import api from "../../services/api";

function Login() {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [showPassaword, setShowPassword] = useState(false);
    const userRef = useRef(null);
    const passwordRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { handleLogin } = useContext(AuthContext);

    const handleValidation = async (codUsuario, hash) => {
        setError("");

        if (! codUsuario && ! hash) {
            if (! user) {
                setError("Informe o usuário");
                return;
            } else if (! password) {
                setError("Informe a senha");
                return;
            }   
        }

        setLoading(true);

        let response = await handleLogin(user, password, "", codUsuario, hash);

        setLoading(false);            

        if (response && response.message)
            setError(response.message);
    };

    function handleEnter(e) {
        if (e.key.toLowerCase() === "enter") 
            if (e.target.id === "input_usuario")
                passwordRef.current.focus();
            else if (e.target.id === "input_senha")
                handleValidation(null, null);
    }

    function toggleShowPassword() {
        setShowPassword(showPassaword => !showPassaword);

        if (! showPassaword) 
            passwordRef.current.type = "text";
        else
            passwordRef.current.type = "password";
        
        passwordRef.current.focus();
    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("login"));

        if (user && user?.abrev)
            setUser(user?.abrev);

        if (user?.token) {
            user.token = "";
            localStorage.setItem("login", JSON.stringify(user));
        }

        api.defaults.headers.Authorization = undefined;            

        const codUsuario = getParamURL("codUsuario");
        const hash = getParamURL("hash");

        if (codUsuario && hash) {
            handleValidation(codUsuario, hash);
        }
    }, []);

    return (
        <div className="container-login">
            <div className="login">
                <img alt="logo" src={require("../../assets/img/logo_defferrari.png")} className="logo" />

                <span className="span-alert">{error}</span>

                <div className="container-input">
                    <FaUserLarge size={14} color="gray" />
                    <input 
                        id="input_usuario"
                        ref={userRef}
                        className="input"
                        autoComplete="off"
                        placeholder="Usuário"
                        value={user}
                        onChange={e => setUser(e.target.value.toUpperCase())}
                        onKeyDown={handleEnter}
                    />
                </div>

                <div className="container-input">
                    <FaLock size={14} color="gray" />
                    <input 
                        id="input_senha"
                        ref={passwordRef}
                        type="password"
                        className="input"
                        placeholder="Senha"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleEnter}
                    />
                    {showPassaword
                    ? <FiEyeOff size={20} color="gray" style={{cursor: "pointer"}} onClick={toggleShowPassword} />
                    : <FiEye size={20} color="gray" style={{cursor: "pointer"}} onClick={toggleShowPassword}/>
                    }
                </div>    

                {loading ?
                    <Oval
                        height={32}
                        width={32}
                        color="#b72126"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#b72126"
                        strokeWidth={2}
                        strokeWidthSecondary={4}

                    />
                : <ButtonOK title="Entrar" onClick={() => handleValidation(null, null)}/>            
                }

                <span className="version">Versão {Package.version}</span>
            </div>
        </div>
    );
};

export default Login;