import { toast, Flip } from "react-toastify";

export function toastError(text, timer=1000) {
    toast.error(text, {
        position: "top-center",
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip
    });    
}

export function toastSuccess(text, timer=1000) {
    toast.success(text, {
        position: "top-center",
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip
    });    
}

export function toastInfo(text, timer=1000) {
    toast.info(text, {
        position: "top-center",
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip
    });    
}

export function dateNowBR() {
    let dia = new Date().toLocaleString("pr-BR", {day: "2-digit"});
    let mes = new Date().toLocaleString("pr-BR", {month: "2-digit"});
    let ano = new Date().toLocaleString("pr-BR", {year: "numeric"});
    return dia + "/" + mes + "/" + ano; //DD/MM/YYYY
}

export function timeNow(segundos = true) {
    let hora = new Date().getHours();
    if (hora < 10)
        hora = "0" + hora;

    let minuto = new Date().getMinutes();
    if (minuto < 10)
        minuto = "0" + minuto

    let segundo = new Date().getSeconds();
    if (segundo < 10)
        segundo = "0" + segundo;

    if (segundos) 
        return hora + ":" + minuto + ":" + segundo;
    else
        return hora + ":" + minuto;
}

export function dateTimeNowBR() {
    return dateNowBR() + " " + timeNow(false);
}

export function getParamURL(param) {
    const urlParams = new URLSearchParams(window.location.search);
    const temp = urlParams?.get(param);

    return temp ? temp : "";
}