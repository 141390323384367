import "./style.css";
import Header from "../Header";

const Page = ({ children }) => {
    return (
        <div className="page-content">
            <Header />

            {children}
        </div>
    );
};

export default Page;