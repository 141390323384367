import './style.css';
import Modal from 'react-modal'; 

import { BsCheck2 } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";

export default function TelaGeral(props) {
    const sizeButton = 28;

    return (
        <Modal 
            isOpen={props.isOpen} 
            style={{overlay: {zIndex: 100}}}
            onRequestClose={props.onRequestClose} 
            appElement={document.getElementById("root")}
            className={props.className ? props.className : "tela-geral-container"}
        >
            <div className="tela-geral-content">
                <div className="tela-geral-topo">
                    <div className="tela-geral-topo-titulo">
                        <span>{props.titulo}</span>
                    </div>

                    <div className="tela-geral-topo-botoes">
                        {props?.buttonAux1}
                        {props.handleConfirmacao &&
                        <BsCheck2 size={sizeButton} className="tela-geral-topo-botao" onClick={props.handleConfirmacao}/>
                        }
                        <IoCloseOutline size={sizeButton} className="tela-geral-topo-botao" title="Fecha" onClick={props.onRequestClose}/>
                    </div>
                </div>

                <div className="tela-geral-main">
                    {props.children}
                </div>

            </div>
        </Modal>
    )
};
