import axios from "axios";
import deffDB from "./deff_db.json";
import { toastError } from "../library";

let baseURL = "";

if (deffDB?.baseURL) 
    baseURL += deffDB?.baseURL;
else {
    if (deffDB?.baseURLProcotol) 
        baseURL += deffDB?.baseURLProcotol + "://";

    baseURL += window.location.hostname;

    if (deffDB?.baseURLPort)
        baseURL += ":" + deffDB?.baseURLPort;
}

export default axios.create({
    baseURL: baseURL,
    timeout: 10000,
});

export function MENSAGEM_ERRO_HTTP() {
    toastError("Não foi possível conectar com o servidor.", 2500);
}
