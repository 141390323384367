import { useContext } from "react";
import "./style.css";

import { AuthContext } from "../../context/Auth";

const Header = () => {
    const { handleLogout } = useContext(AuthContext);

    const user = JSON.parse(localStorage.getItem("login"));
    return (
        <div className="header-content">
            <div className="header-user">
                <span>Olá, {user?.apelido}</span>
            </div>

            <div className="header-exit">
                <button className="header-exit-button" onClick={handleLogout}>
                    <span>Sair</span>
                </button>
            </div>
        </div>
    );
};

export default Header;