import React, { useContext } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { AuthContext, AuthProvider } from "../context/Auth";

import Login from "./Login";
import Home from "./Home";

function CustomRoute({ isPrivate, ...rest }) {
    const { loading, authenticated } = useContext(AuthContext);
  
    if (loading) {
      return null
    }

    if (isPrivate && !authenticated) {
      return <Redirect to="/login" />
    }

    return <Route {...rest} />;
  }

export default function Routes() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <CustomRoute exact path="/login" component={Login} />
            <CustomRoute isPrivate exact path="/" component={Home} />         
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    );
};