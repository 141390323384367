import React, {useContext} from "react";
import "./style.css";

import { AuthContext } from "../../context/Auth";
import { IoMdPrint } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import { BiExit} from "react-icons/bi";

import { Oval } from  "react-loader-spinner";


export function ButtonOK( {title, onClick, children} ) {
    return (
        <button className="buttonOK" onClick={onClick}>{title}
            {children}
        </button>
    );
};

export function ButtonLogout() {
    const { handleLogout } = useContext(AuthContext);
    return (
        <BiExit size={26} color="gray" onClick={() => handleLogout()} />
    );
};

export function ButtonPesquisa(props) {
    return (
        props?.loading
        ?
        <Oval
            height={32}
            width={32}
            color="#b72126"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#b72126"
            strokeWidth={2}
            strokeWidthSecondary={4}
        />
        :
        <button 
            className="buttonPesquisa" 
            onClick={props.onClick}
        >          
            <BsSearch 
                size={16} 
            />
        </button>
    );
};

export function ButtonImprime(props) {
    return (
        props?.loading
        ?
        <Oval
            height={32}
            width={32}
            color="#b72126"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#b72126"
            strokeWidth={2}
            strokeWidthSecondary={4}
        />
        :        
        <button 
            className="buttonImprime" 
            onClick={props.onClick}
        >          
            <IoMdPrint size={20} />

            {props.titulo ? <span>Visualiza</span> : null}
        </button>
    );
};