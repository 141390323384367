import React, {createContext, useState, useEffect} from "react";
import api, {MENSAGEM_ERRO_HTTP} from "../../services/api";
import { useHistory } from "react-router-dom";
import LoadingModal from "../../components/LoadingModal";
import { dateTimeNowBR } from "../../library";

const AuthContext = createContext({});

function AuthProvider({children}) {
    const history = useHistory();

    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userLogged, setUserLogged] = useState({});

    useEffect(() => {
        api.defaults.headers.common["content-type"] = "application/x-www-form-urlencoded";
        api.defaults.headers["Access-Control-Allow-Origin"] = "*";

        (async () => {
            try {
                let login = JSON.parse(localStorage.getItem("login"));
                if (login && login?.token) {
                    let { data } = await handleLogin("", "", login?.token, "", "");

                    if (data && data.code === 401) {
                        login.token = "";
                        localStorage.setItem("login", JSON.stringify(login));
                    }
                }               
            }
            catch {}
            finally {
                setLoading(false);
            }
        })();
    }, []);

    async function handleLogin(user, password, token, codUsuario, hash) {
        let response;

        try {
            response = await (await api.post(
                "/login",
                {
                    user: user ? user : "",
                    password: password ? password : "",
                    token: token ? token : "",
                    password: password ? password : "",
                    codusuario: codUsuario ? codUsuario : "",   
                    hash: hash ? hash : "",                    
                    agendaServico: true
                }
            )).data;

            if (! response.error) {
                let login = JSON.parse(localStorage.getItem("login"));
                if (! login) {
                    login = {};
                }
                if (response?.userName)
                    login.nome = response?.userName;

                if (response?.userAbrev)
                    login.abrev = response?.userAbrev;     
                
                if (response?.userApelido)
                    login.apelido = response?.userApelido; 

                if (response?.token) {
                    login.token = response?.token;
                }                  

                if (response?.userDiasAgendaAnterior >= 0) {
                    login.diasAgendaAnterior = response?.userDiasAgendaAnterior;
                }

                if (response?.userDiasAgendaPosterior >= 0) {
                    login.diasAgendaPosterior = response?.userDiasAgendaPosterior;
                }                
                
                if (response?.id) {
                    login.data = dateTimeNowBR();
                    localStorage.setItem("login", JSON.stringify(login));
                    setUserLogged(login);
                }

                setUserLogged(login);

                api.defaults.headers.Authorization = `Bearer ${response.token}`;
                setAuthenticated(true);

                if (user && password)
                    history.push("/"); 

                if (codUsuario && hash)
                    history.push("/"); 
            }
        } catch {
            if (user && password)
                MENSAGEM_ERRO_HTTP();
        } finally {
            return response;
        }
    };

    function handleLogout() {
        setAuthenticated(false);
        let login = JSON.parse(localStorage.getItem("login"));
        login.token = "";
        localStorage.setItem("login", JSON.stringify(login));
        api.defaults.headers.Authorization = undefined;
        history.push("/login");
    };

    if (loading)
        return <LoadingModal />

    return (
        <AuthContext.Provider
            value={{
                history,
                authenticated,
                setAuthenticated,
                handleLogin,
                handleLogout,
                userLogged,
                setUserLogged
            }}
        >
            {children}
        </AuthContext.Provider>
    )
};

export {AuthContext, AuthProvider};