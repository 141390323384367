import React from "react";
import "./style.css";
import Modal from "react-modal";
import { Oval } from  "react-loader-spinner";

export default function Loading({ size }) {
    return (
        <Oval
            height={size}
            width={size}
            color="#b72126"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#b72126"
            strokeWidth={2}
            strokeWidthSecondary={4}
        />  
    );
};